<i18n locale="th" lang="yaml" src="@i18n/drone/drone.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
page.title : "{name} :: ลงทะเบียน"
page.header : "ลงทะเบียนใช้งาน Drone"
page.description : "ทำการลงทะเบียนผู้ใช้/ผู้ซื้อ Drone นี้ เพื่อเก็บข้อมูลอ้างอิงในระบบ"

register.select_customer.label : "ระบุเจ้าของเครื่อง"
register.submit.label: "ยื่นแบบลงทะเบียน"
register.confirm.title : "ยืนยันการยื่นลงทะเบียน"
register.confirm.message : "ยืนยันการยื่นลงทะเบียนสำหรับ Drone {name}, หลังจากยืนยันแล้ว การแก้ไขข้อมูลเหล่านี้ต้องแจ้งทางระบบเท่านั้น"
register.success : "ทำการยื่นลงทะเบียนให้ Drone {name} สำเร็จ"
register.error.invalid_status : "Drone เครื่องนี้ลงทะเบียนไปแล้ว"
register.send_request.done: "โปรดยืนยันข้อมูลกับทาง HGR"

register.customer.title : "ระบุเจ้าของเครื่อง"

drone.field.warranty_start_date.help.default : "ระยะเวลารับประกัน {duration} วัน"
drone.field.warranty_start_date.help.value : "จะหมดอายุการรับประกัน ณ วันที่ {date} (ระยะเวลารับประกัน {duration} วัน)"

drone.field.customer.help : "เจ้าของเครื่องที่ลงทะเบียน"
drone.field.latest_flight_time_sec.help : "ค่าตั้งต้น Flight Time ของ Drone ตัวนี้"
drone.field.policy.help : "รูปแบบการใช้งาน Drone ถ้าตั้งเป็น Lock 7 วัน สำหรับ Drone ที่ติด Finance"


</i18n>

<template>
	<div class="page">
		<my-page-header
			:title="$t('page.header')"
			:description="$t('page.description')"/>

		<a-card :bordered="false">
			<BlockPane :block="changeRequest.length > 0">
				<span slot="message">
					{{$t('register.send_request.done')}}
				</span>
				<a-form 
					layout="horizontal"
					:label-col="labelCol"
					:wrapper-col="wrapperCol"
					:form="formObj"
					@submit.prevent="handleSubmit">
					<a-form-item :label="'* ' + $t('drone.field.customer')"
						:validate-status="$notEmpty(selectedCustomerMessage) ? 'error' : ''"
						:help="selectedCustomerMessage"
						:extra="$t('drone.field.customer.help')">
						<a-button  icon="user" type="dashed" @click="handleSelectCustomer">
							{{$t('register.select_customer.label')}}
						</a-button>
						<CustomerLiteCard v-show="selectedCustomer.mode != 'none'"
							size="small"
							:customer="selectedCustomer.customer"
							:bordered="true"
							class="register-customer-card"/>
					</a-form-item>

					<a-form-item :label="$t('drone.field.warranty_start_date')"
						:extra="warrantyMessage">
						<MyDatePicker
						v-decorator="['warranty_start_date',
								{
									rules: [
										{ required: true ,message: $tt('validate.required','drone.field.warranty_start_date') },
									],
								}]"
							:disabled-date="disabledWarrantyDate"
							format="DD MMMM YYYY"
							@change="changeWarrantyDate"/>
					</a-form-item>

					<a-form-item :label="$t('drone.field.policy')"
						:extra="$t('drone.field.policy.help')">
						<a-radio-group
							v-decorator="['policy',
								{
									rules: [
										{ required: true ,message: $tt('validate.required','drone.field.warranty_start_date') },
									],
									'initialValue': 'none',
								}]"
						:options="policyOptions"/>
						</a-form-item>


					<a-form-item :label="$t('drone.field.latest_flight_time_sec')"
						:extra="$t('drone.field.latest_flight_time_sec.help')">
						<FlightTimeInput
							v-decorator="[
								'latest_flight_time_sec' ,
								{
									rules: [
									{ required: true ,message: $tt('validate.required','drone.field.latest_flight_time_sec') },
								] },
							]"/>
					</a-form-item>

					<a-form-item v-bind="wrapperOnlyLayout">
						<a-button
							:disabled="formLoading"
							icon="left"
							size="large"
							:style="{ 'margin-right': '4px' }"
							@click="goBack">
							{{$t('common.back')}}
						</a-button>
						<a-button
							:loading="formLoading"
							type="info"
							html-type="submit"
							class="btn-submit"
							icon="file-add"
							size="large">
							{{$t('register.submit.label')}}
						</a-button>
					</a-form-item>
				</a-form>
			</BlockPane>
		</a-card>

		<CustomerModal
			v-model="selectedCustomer"
			:modal-title="$t('register.customer.title')"
			:visible="visible"
			:can-create="true"
			@select="handleSelectModal"
			@close="handleCloseModal"/>
	</div>
</template>

<script>
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import MyDatePicker from '@components/input/MyDatePicker.vue'
import FlightTimeInput from "@components/input/FlightTimeInput.vue"
import CustomerModal from "@components/customer/CustomerModal.vue"
import CustomerLiteCard from "@components/customer/CustomerLiteCard.vue"
import dayjs from "dayjs"
import axios from "axios"
import {Radio} from "ant-design-vue"
import {updateFieldsValue,assignFieldErrors,getEnumSelectOptions} from '@utils/formUtil'
import {emptyDrone} from "@utils/objectUtil"
import ModelError from '@utils/errors/ModelError'
import BlockPane from '@components/common/BlockPane.vue'

export default {
	components : {
    "a-radio-group": Radio.Group,
    CustomerModal,
    CustomerLiteCard,
    MyDatePicker,
    FlightTimeInput,
    BlockPane
} ,
	mixins : [HasAntdFormMixin] ,
	page() {
		return {
			title : this.$t('page.title',{name:this.drone.serial_no})
		}
	} ,
	props : {
		droneData : {
			type : null,
			default : emptyDrone()
		} ,
	} ,
	data() {
		return {
			selectedCustomerMessage : null,
			warrantyEndDate : null,
			formLoading : false ,
			visible : false,
			selectedCustomer : {
				mode : 'none' ,
				customer : {}
			},
		}
	} ,
	computed : {
		drone() {
			return this.droneData.drone ? this.droneData.drone : {}
		} ,
		changeRequest() {
			return this.droneData.change_request ? this.droneData.change_request : []
		},
		droneLot() {
			return this.droneData.lot ? this.droneData.lot : {}
		} ,
		droneModel() {
			return this.droneData.model ? this.droneData.model : {}
		} ,
		policyOptions() {
			return getEnumSelectOptions(this,"drone.policy",['none','locked_7d'])
		} ,
		warrantyMessage() {
			const duration = this.droneModel.warranty_duration
			if (this.warrantyEndDate) {
				return this.$t('drone.field.warranty_start_date.help.value',{duration,'date' : this.$dayjs(this.warrantyEndDate).format("LL")})
			} else {
				return this.$t('drone.field.warranty_start_date.help.default',{duration})
			}
		}
	} ,
	watch : {
		droneData : {
			handler() {
				this.preProcess()
			} ,
			deep: true
		}
	} ,
	mounted(){
		this.preProcess()
	} ,
	methods : {
		preProcess() {
			if (!this.$authorize('register','drone')) {
				this.$pageError({name : 'error_forbidden'})
			}
			if (this.drone.id > 0) {
				if (this.drone.drone_status != 'new') {
					this.$pageError({name : 'error_forbidden'},{message : this.$t("register.error.invalid_status")})
				} else {
					updateFieldsValue(this.formObj,{latest_flight_time_sec : this.drone.latest_flight_time_sec})
				}
			}
		} ,
		disabledWarrantyDate(current) {
			return current && this.droneLot.manufacture_date && current < dayjs(this.droneLot.manufacture_date)
		} ,
		changeWarrantyDate(date) {
			if (date) {
				this.warrantyEndDate = dayjs(date).add(this.droneModel.warranty_duration,'day')
			} else {
				this.warrantyEndDate = null
			}
		} ,
		handleSelectCustomer() {
			this.visible = true
		} ,
		handleCloseModal() {
			this.visible = false
		} ,
		handleSelectModal(selected) {
			if (selected.mode == 'none') {
				this.selectedCustomerMessage = this.$tt('validate.required','drone.field.customer')
			} else {
				this.selectedCustomerMessage = null;
			}
			this.handleCloseModal()
		} ,
		handleSubmit() {
			// validate before confirm
			this.formObj.validateFields((err,values) => {
				if (this.selectedCustomer.mode == 'none') {
					this.selectedCustomerMessage = this.$tt('validate.required','drone.field.customer')
				} else {
					this.selectedCustomerMessage = null;
				}
				if (err || this.selectedCustomerMessage) {
					return
				}
				this.$confirm({
					title : this.$t('register.confirm.title') ,
					content : this.$t('register.confirm.message',{name: this.drone.name}) ,
					maskClosable : true,
					onOk: () => {
						this.formLoading = true
						const postData = {
							customer : this.selectedCustomer.customer,
							register : values
						}
						axios.post('/api/drones/'+this.drone.id+'/register-request',postData).then((response)=> {

							this.$message.success(this.$t('register.success',{"name" : this.drone.name}))
							this.$router.go()
						}).catch((error) => {
							if (error instanceof ModelError) {
								if (error.getFieldErrorsByModelKey('customer')) {
									this.selectedCustomerMessage = this.$tt('validate.invalid','drone.field.customer')
								}
								const registerErrors = error.getFieldErrorsByModelKey('register');
								if (registerErrors) {
									assignFieldErrors(this.formObj,registerErrors.field_errors)
								}
								this.displayValidateErrorMessage()
							} else {
								this.formSubmitErrors(error)
							}
						}).finally(()=> {
							this.formLoading = false
						});
					}
				})
			})
		} ,
		goBack() {
			this.$open({name:"drone/view" , params : {serial_no : this.drone.serial_no}})
		}
	}
}
</script>

<style lang="less" scoped>
.register-customer-card{
	max-width: 450px;
	margin-top : 8px;
}
</style>
